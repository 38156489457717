export const labelColors = {
    'flexible savings': 'red',
    'most popular': 'red',
    'charlie\'s Choice': 'red',
    'best value': 'red',
    'secure savings': 'red',
    'max security': 'red',
    '100% green': 'green',
    'no deposit': 'red'
}

export function getURLParam(paramName) {
    var url = window.location.href;
    var params = url.split('?')[1];
	if (!params) return '';
    var paramsArray = params.split('&');
    var paramValue = '';
    paramsArray.forEach(function(param) {
      var paramSplit = param.split('=');
      if (paramSplit[0] === paramName) {
        paramValue = decodeURIComponent(paramSplit[1]);
      }
    });
    return paramValue;
}

function getCookie(cookieName) {
	var cookieValue = '';
	if (document.cookie && document.cookie !== '') {
	  var cookies = document.cookie.split(';');
	  for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i].trim();
		if (cookie.substring(0, cookieName.length + 1) === (cookieName + '=')) {
		  cookieValue = decodeURIComponent(cookie.substring(cookieName.length + 1));
		  break;
		}
	  }
	}
	return cookieValue;
}

export function getUtmParameter() {
	const utmArr = ['utm_content', 'utm_campaign', 'utm_source', 'utm_medium', 'utm_term'];
	const utmMap = new Map();
	for (let item of utmArr) {
		const val = getURLParam(item);
		if (val) {
			utmMap.set(item, val);
		}
	}
	let utms = '',
		utmsCookie = '';

	if (utmMap.size > 0) {
		utmMap.forEach((val, idx) => utms += `${`${utms !== '' ? '&' : ''}${idx}=${val}`}`);
	}
	if (utms) {
		return utms;
	}
	else {
		const utmMapCookie = new Map();
		for (let item of utmArr) {
			const val = getCookie(item);
			if (val) {
				utmMapCookie.set(item, val);
			}
		}
		if (utmMapCookie.size > 0) {
			utmMapCookie.forEach((val, idx) => utmsCookie += `${`${utmsCookie !== '' ? '&' : ''}${idx}=${val}`}`);
		}
		
		if (utmsCookie) {
			return utmsCookie;
		}
	}
	
	return '';
}

export function extractParameters(url, exclude) {
    const parsedUrl = new URL(url);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const parameters = [];
  
    for (const [key, value] of searchParams.entries()) {
      if (exclude && key === exclude) continue;
      parameters.push(`${key}=${value}`);
    }
  
    return parameters.join('&');
  }