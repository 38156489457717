import { useState } from "react";
import { IS_DEV } from "../config";
import {
    StyledHeader, StyledHeaderInner, StyledHeaderLogo, StyledHeaderMenuBar,
    StyledHeaderMenu, StyledHeaderMenuItem,
    // StyledHeaderMenuDropdown, StyledHeaderButton
} from "./styles/Header.styled";
import { extractParameters } from "../common";

const Header = ({ gcPhone, utms }: {
    gcPhone: string;
    utms?: string;
}) => {
    const [ showMenu, setShowMenu ] = useState(false);
    // const [ showDropdown, setShowDropdown ] = useState(false);

    const queryStrings = extractParameters(window.location, 'product_id');

    return (
        <StyledHeader>
            <StyledHeaderInner>
                <StyledHeaderLogo href={`https://goodcharlie.com/${queryStrings ? `?${queryStrings}` : ''}`}>
                    <img src="/images/logo.svg" alt="" />
                    { IS_DEV && <span style={{marginLeft:12,backgroundColor:'#008b8b',color:'#fff',fontWeight:700,padding:'2px 6px'}}>DEV</span> }
                </StyledHeaderLogo>
                {/* <StyledHeaderMenuBar active={showMenu} onClick={() => setShowMenu(!showMenu)} /> */}
                {/* <StyledHeaderMenu show={showMenu}> */}
                    {/* <StyledHeaderMenuItem hasDropdown active={showDropdown}>
                        <span onClick={() => setShowDropdown(!showDropdown)}>Why GoodCharlie</span>
                        <StyledHeaderMenuDropdown>
                            <a href="https://goodcharlie.com/why-goodcharlie">Our Difference</a>
                            <a href="https://goodcharlie.com/pack-benefits">Pack Benefits</a>
                            <a href="https://goodcharlie.com/partners">Partners</a>
                        </StyledHeaderMenuDropdown>
                    </StyledHeaderMenuItem> */}
                    {/* <StyledHeaderMenuItem>
                        <a href="https://compare.goodcharlie.com/">Compare Us</a>
                    </StyledHeaderMenuItem>
                    <StyledHeaderMenuItem>
                        <a href="https://myaccount.goodcharlie.com/">My Account</a>
                    </StyledHeaderMenuItem> */}
                    <StyledHeaderMenuItem className="me-0">
                        <a href={`tel:${gcPhone ? gcPhone : '1-800-205-5230'}`}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.6667 11.2797V13.2797C14.6675 13.4654 14.6294 13.6492 14.555 13.8193C14.4807 13.9894 14.3716 14.1421 14.2348 14.2676C14.0979 14.3932 13.9364 14.4887 13.7605 14.5482C13.5847 14.6077 13.3983 14.6298 13.2134 14.6131C11.1619 14.3902 9.19137 13.6892 7.46004 12.5664C5.84926 11.5428 4.48359 10.1772 3.46004 8.56641C2.33336 6.82721 1.6322 4.84707 1.41337 2.78641C1.39671 2.60205 1.41862 2.41625 1.4777 2.24082C1.53679 2.0654 1.63175 1.9042 1.75655 1.76749C1.88134 1.63077 2.03324 1.52155 2.20256 1.44675C2.37189 1.37196 2.55493 1.33325 2.74004 1.33307H4.74004C5.06357 1.32989 5.37723 1.44446 5.62254 1.65543C5.86786 1.8664 6.02809 2.15937 6.07337 2.47974C6.15779 3.11978 6.31434 3.74822 6.54004 4.35307C6.62973 4.59169 6.64915 4.85102 6.59597 5.10033C6.5428 5.34964 6.41928 5.57848 6.24004 5.75974L5.39337 6.60641C6.34241 8.27544 7.72434 9.65737 9.39337 10.6064L10.24 9.75974C10.4213 9.5805 10.6501 9.45697 10.8994 9.4038C11.1488 9.35063 11.4081 9.37004 11.6467 9.45974C12.2516 9.68544 12.88 9.84199 13.52 9.92641C13.8439 9.97209 14.1396 10.1352 14.3511 10.3847C14.5625 10.6343 14.6748 10.9528 14.6667 11.2797Z" strokeWidth="1.33697" strokeLinecap="round" strokeLinejoin="round"></path></svg><span className="ms-1">{gcPhone ? gcPhone : '1-800-205-5230'}</span></a>
                    </StyledHeaderMenuItem>
                    {/* <StyledHeaderButton href="https://signup.goodcharlie.com/zip-code">Sign Up</StyledHeaderButton> */}
                {/* </StyledHeaderMenu> */}
            </StyledHeaderInner>
        </StyledHeader>
    )
}

export default Header