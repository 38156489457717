/*
 * APIs are powered by PowerLego
 * Website: https://www.powerlego.com/
 */

import { API_URL, ACC_KEY, IS_DEV, GC_URL } from '../config';

/*
 * Address Validator
 * Use cases:        https://www.powerlego.com/cases/address_validator
 * Postman examples: https://documenter.getpostman.com/view/4831254/SzKYPH3c#ca1bdf1f-2318-4fed-aaeb-88708d7c34eb
 */
export const fetchAddress = async (val) => {
    const string = val.toString().replace(/#/, '')

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
    
    const formdata = new FormData();
    formdata.append("term", string);
    
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v1/address_validator`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchUtility = async (zipcode) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("zipcode", zipcode);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v1/get_utility`

    const response = await fetch(url, requestOptions)
    return response.json()
}

// export const fetchPremiseDetail = async (zipcode, address) => {
//     const myHeaders = new Headers();
//     myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

//     const formdata = new FormData();
//     formdata.append("zipcode", zipcode);
//     formdata.append("address", address);

//     const requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: formdata,
//         redirect: 'follow'
//     };

//     const url = `${API_URL}v2/premise/detail`

//     const response = await fetch(url, requestOptions)
//     return response.json()
// }

export const fetchProducts = async (tduCode, productId, utmContent, esiid) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${API_URL}v2/gc/products?tdu_name=${tduCode}`;

    if (productId) url += `&product_id=${productId}`;
    if (utmContent) url += `&utm_content=${utmContent}`;
    if (esiid) url += `&esiid=${esiid}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: myHeaders
    });

    return response.json()
}

export const fetchRateCompare = async (tduCode, term) => {
    let url = `https://0gk5f0wlpd.execute-api.us-east-2.amazonaws.com/v1/SideBySide/GoodCharlie`;

    const formdata = new FormData();
    formdata.append("utility_code", tduCode);
    formdata.append("term", term);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const fetchValidateAddress = async (esiid) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${API_URL}v2/gc/address_validate`;

    const formdata = new FormData();
    formdata.append("esiid", esiid);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const fetchValidatePromoCode = async (promoCode) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${API_URL}v2/gc/promo_code_validate`;

    const formdata = new FormData();
    formdata.append("promo_code", promoCode);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const fetchValidateEmail = async (email) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${API_URL}v2/gc/email_validate`;

    const formdata = new FormData();
    formdata.append("email", email);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const fetchFaqs = async () => {
    const response = await fetch('https://signup.goodcharlie.com/signup/api/v1/get/picklist/pp_faq', {
        method: 'GET'
    });

    return response.json()
}

export const fetchPlans = async (zipcode, utmContent, utmCampaign, utmSource, utmMedium, utmTerm) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let urlStrings = `zipcode=${zipcode}`;
    if (utmContent) urlStrings += `&utm_content=${utmContent}`;
    if (utmCampaign) urlStrings += `&utm_campaign=${utmCampaign}`;
    if (utmSource) urlStrings += `&utm_source=${utmSource}`;
    if (utmMedium) urlStrings += `&utm_medium=${utmMedium}`;
    if (utmTerm) urlStrings += `&utm_term=${utmTerm}`;

    let url = `${API_URL}v2/gc/webproducts?${urlStrings}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: myHeaders
    });

    return response.json()
}

export const fetchGlobalSettings = async (utmContent/*, ip*/) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${API_URL}v2/gc/global_settings`;

    const formdata = new FormData();
    formdata.append("utm_content", utmContent);
    // formdata.append("ip_address", ip);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const getMaintenanceData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${GC_URL}get/picklist/maintenance_mode`;

    const response = await fetch(url, {
        method: 'GET'
    });

    return response.json();
}