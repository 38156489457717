import styled from 'styled-components';


export const ReviewRatingSection = styled.div`
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 2rem;

    @media (max-width: 540px) {
        gap: 5px;
    }
`

export const ReviewRating = styled.div`
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 15px;
    .puc-icon, .google-icon {
        width: 90px;
        height: 90px;
    }
    p {
        // display: flex;
        // align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 700;
        text-align:left;
        width: 100%;
        margin-bottom: 0;
        color: #000;
    }

    @media (max-width: 540px) {
        gap: 5px;
        .puc-icon, .google-icon {
            width: 75px;
            height: 75px;
        }
    }
`

export const Starts = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 10px;

    @media (max-width: 540px) {
        margin-bottom: 5px;
        img {
            width: 15px;
            height: 15px;
        }
    }
`